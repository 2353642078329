<template>
  <div id="login">
    <div class="body">
      <div class="head">
        <div class="logo">
          <img class="img" src="../../assets/image/login-logo.png" />
        </div>
        <div class="text-box">
          <div class="title">阿六到家 · 管理中台</div>
          <div class="version">版本号：V{{ version }}</div>
        </div>
      </div>
      <template v-if="!wechatLink">
        <a-spin :spinning="spinning" size="large" />
      </template>
      <template v-if="lists.length">
        <div class="content">
          <div class="title">{{ nickname }}，您好！请选择需要登入的账号</div>

          <div class="scroll">
            <div class="lists" v-for="(item, index) in lists" :key="item.key">
              <div class="sub-title" v-if="item.data.length">
                {{ item.text }}
              </div>
              <div class="ul">
                <div
                  class="li"
                  v-for="(li, subIndex) in item.data"
                  :key="li.id"
                  @click="onSelect(index, subIndex, li)"
                >
                  <div
                    class="inner"
                    style="display: block"
                    :class="{
                      active:
                        selectIndex === index && selectSubIndex === subIndex
                    }"
                  >
                    <div class="name">
                      <img
                        class="icon"
                        src="../../assets/image/icon-user.png"
                        alt="icon"
                      />
                      <div class="label">{{ typeText[li.userType] }}</div>
                      <div class="value" v-if="li.userType !== 'Madmin'">
                        ：{{ li.username }}
                      </div>
                    </div>
                    <div class="other" v-if="li.userType === 'Proxy'"></div>
                    <div v-for="(item, index) in typeText" :key="index">
                      <div class="other" v-if="li.userType === index">
                        <div v-if="li.userType === 'Proxy'">
                          代理地区：{{ li.province }}/{{ li.city }}/{{
                            li.district
                          }}
                        </div>
                        <div v-else>
                           角色：{{ li.role_name }}
                        </div>
                      </div>
                    </div>
                    <!-- <div class="other" v-if="li.userType === 'store'">
                      角色：{{ li.role_name }}
                    </div>
                    <div class="other" v-if="li.userType === 'community'">
                      角色：{{ li.role_name }}
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="footer">
            <div class="btn cancel" @click="cancelTap">
              <div class="icon">
                <img class="img" src="../../assets/image/icon-wechat.png" />
              </div>
              <span class="text">重新扫码</span>
            </div>
            <div
              class="btn submit"
              :class="{
                active: selectIndex > -1 && selectSubIndex > -1
              }"
              @click="selectSubmit"
            >
              <span class="text">登入账号</span>
            </div>
          </div>
        </div>
      </template>
      <div class="content" v-else>
        <iframe
          id="iframe"
          :src="wechatLink"
          frameborder="0"
          name="iframe"
          sandbox="allow-scripts allow-top-navigation allow-same-origin"
          v-if="wechatLink"
        ></iframe>
      </div>
    </div>

    <footer>
      <div class="text">
        <span class="a">
          Copyright@2019 - 2021 aliu.com 广州老战友网络科技版权所有
        </span>
        <a class="a" href="https://beian.miit.gov.cn" target="_blank">
          粤ICP备19139754号-1
        </a>
      </div>
    </footer>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      wechatLink: '',
      lists: [],
      typeText: {
        Proxy: '代理企业',
        Madmin: '中台管理账号',
        Lector: '理论讲师',
        Training: '实操讲师',
        business: '渠道商',
        bterminal: '渠道商终端',
        store: '中台管理账号',
        community: '渠道经理'
      },
      selectIndex: -1,
      selectSubIndex: -1,
      nickname: '',
      spinning: true
    }
  },
  computed: {
    ...mapState(['version'])
  },
  created() {
    const query = this.$route.query
    if (query.code) {
      const data = {
        code: query.code
      }
      this.getAccount(data)
    } else if (query.status === 'change') {
      this.changeAccount()
    } else {
      this.onWechatLogin()
      // this.changeAccount()
    }
  },
  methods: {
    onWechatLogin() {
      // 微信登录

      this.$axios.wechatLogin().then((res) => {
        this.wechatLink = res.data.data.url
      })
    },
    getAccount(e) {
      // 获取账号列表
      this.$axios.getAccount(e).then((res) => {
        // console.log("获取账号列表",res.data.data)
        this.dataProcessing(res.data.data)
        this.$router.replace('/login')
      })
    },
    changeAccount() {
      // 切换账号
      this.$axios.onChangeAccount().then((res) => {
        console.log('切换账号', res.data.data)
        window.sessionStorage.removeItem('menuFormat')
        this.dataProcessing(res.data.data)
      })
    },
    dataProcessing(d) {
      const lists = d.data
      this.nickname = d.nickname
      const newLists = [
        {
          key: 0,
          text: '业务账号',
          data: []
        },
        {
          key: 1,
          text: '管理账号',
          data: []
        }
      ]
      lists.forEach((e) => {
        if (e.type === 1) {
          // 中台业务账号
          newLists[0].data.push(e)
        } else if (e.type === 3) {
          // 管理账号
          newLists[1].data.push(e)
        }
      })
      this.lists = newLists
      console.log('lists', this.lists)
      this.spinning = false
    },
    cancelTap() {
      // 取消，重新扫码
      window.sessionStorage.clear()
      window.location.replace('/')
    },
    onSelect(index, subIndex, list) {
      // 账号选择
      this.selectIndex = index
      this.selectSubIndex = subIndex
      // console.log("list",list)
      window.sessionStorage.setItem('list', JSON.stringify(list)) // 登录账号
    },
    selectSubmit() {
      // 账号选择提交
      const item = this.lists[this.selectIndex].data[this.selectSubIndex]

      if (!item.role_id || !item.role_level) {
        this.$message.error('账号未开通，请联系后台管理员')
        return
      }
      const data = {
        id: item.id,
        role_id: item.role_id,
        role_level: item.role_level,
        userType: item.userType
      }
      this.$axios.login(data).then((res) => {
        const val = res.data.data
        this.$store.commit('setToken', val.token)
        window.sessionStorage.setItem('token', val.token)
        this.getUserInfo()
      })
    },
    getUserInfo() {
      // 获取账号信息
      this.$axios.getUserInfo().then((res) => {
        this.$message.success('登录成功')
        const val = res.data.data
        const qiniuData = {
          host: val.qiniuthost,
          token: val.qiniutoken
        }
        this.$store.commit('setQiniuData', qiniuData)
        window.sessionStorage.setItem(
          'permission',
          JSON.stringify(val.permission)
        )
        console.log('qiniuData', qiniuData)
        window.sessionStorage.setItem('qiniuData', JSON.stringify(qiniuData))
        window.sessionStorage.setItem('name', val.userInfo.realName) // 账号持有人
        window.sessionStorage.setItem('userType', val.userType) // 账户类型
        window.sessionStorage.setItem('userName', val.role_name) // 角色类型
        window.sessionStorage.setItem('systemNum', val.userInfo.systemNum) // 登录账号

        setTimeout(() => {
          // 解决登录后因未登录时，就已经匹配好路径，导致面包屑缺失问题，刷新即可解决
          window.location.href = window.location.origin
          // this.$router.replace('/')
        }, 500)
      })
    }
  }
}
</script>
<style scoped>
#login {
  height: 100vh;
  background: url('../../assets/image/bg-login.png') no-repeat;
  background-size: 100% 100%;
}

#iframe {
  width: 300px;
  height: 400px;
  margin: 30px 0 0 216px;
  background-color: #fff;
}

.body {
  width: 852px;
  height: 597px;
  background-color: #fff;
  border-radius: 16px;
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -320px 0 0 -426px;
}

.body .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0 22px 0;
  border-bottom: 1px solid #f5f5f5;
}

.body .logo {
  width: 230px;
  height: 59px;
}

.body .head .title {
  font-size: 18px;
  line-height: 18px;
  color: #29c566;
  font-weight: bold;
}

.body .head .version {
  font-size: 16px;
  line-height: 16px;
  text-align: right;
  color: #b3b3b3;
  margin-top: 8px;
}

.body .content {
  height: 475px;
}

.content .title {
  font-size: 18px;
  line-height: 32px;
  color: #37443a;
  margin-top: 32px;
}

.body .lists {
  flex: 1;
}

.body .sub-title {
  font-size: 16px;
  line-height: 24px;
  color: #808080;
  margin: 24px 0 12px 0;
}

.body .scroll {
  height: 300px;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: auto;
  padding-bottom: 32px;
  box-sizing: border-box;
}

.body .scroll::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.body .ul {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-top: -24px;
}

.body .li {
  width: 50%;
  height: 72px;
  margin-top: 24px;
}

.body .li:nth-child(odd) {
  padding-right: 8px;
}

.body .li:nth-child(even) {
  padding-left: 8px;
}

.body .inner {
  height: 100%;
  border-radius: 2px;
  padding: 10px 12px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}

.body .inner.active,
.body .inner:hover {
  background: rgba(41, 197, 102, 0.05);
  border: 1px solid #29c566;
}

.body .li .name {
  display: flex;
  align-items: center;
}

.body .li .icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.body .li .label,
.body .li .value {
  font-size: 16px;
  line-height: 24px;
}

.body .li .label {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

.body .li .value {
  color: rgba(0, 0, 0, 0.65);
}

.body .li .other {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.25);
  margin: 6px 0 0 28px;
}

.body .footer {
  height: 106px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #f5f5f5;
}

.body .footer .btn {
  width: 166px;
  height: 50px;
  border-radius: 4px;
}

.body .footer .cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.footer .icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.footer .text {
  font-size: 16px;
  line-height: 16px;
}

.footer .cancel .text {
  color: #808080;
}

.footer .submit {
  text-align: center;
  line-height: 50px;
  margin-left: 16px;
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.footer .active {
  cursor: pointer;
  background-color: #29c566;
}

.footer .submit .text {
  color: rgba(0, 0, 0, 0.25);
}

.footer .active .text {
  color: #fff;
}

footer {
  width: 100%;
  height: 14px;
  text-align: center;
  line-height: 14px;
  position: fixed;
  left: 0;
  bottom: 80px;
  z-index: 1;
}

footer .a {
  color: #fff;
  font-size: 15px;
}

.body >>> .ant-spin {
  margin-top: 200px;
}
</style>
